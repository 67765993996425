<template>
  <div :class="direction">
    <img :src="(color && text) ? `/assets/img/hot-offer-${color}.png`: '/assets/img/hot-offer.png'"
      class="hot-offer-badge" alt="hot-offer"/>
    <!-- <p class="badge-text" :class="{'multi-row' : (text.includes(' ') && (device==='desktop' || text.length > 10))}">{{text}}</p> -->
    <p class="badge-text" :class="{'multi-row' : text.includes(' ')}">{{text}}</p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'hot-offer-icon',
  props: {
    color: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    direction: {
      type: String,
      default: 'right',
    },
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
  },
  created() {
    // console.log(this.text.length);
  },
};
</script>
<style scoped>
  .right .hot-offer-badge{
    transform: rotate(90deg);
  }
  .right .badge-text{
    transform: rotate(45deg);
    top: calc(50% - 1.1rem);
    left: calc(50% - 2.1rem);
  }
  .left .hot-offer-badge{
    transform: rotate(0deg);
  }
  .left .badge-text{
    transform: rotate(-45deg);
    top: calc(50% - 1.1rem);
    left: 0;
    /* transform-origin: top left; */
  }
  .hot-offer-badge{
    width: 100%;
    height: 100%;
  }
  .badge-text{
    position: absolute;
    color: white;
    line-height: 1.2rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
  .right .badge-text.multi-row{
    left: calc(50% - 0.8rem);
  }
  .left .badge-text.multi-row{
    left: calc(50% - 3.5rem);
    width: 80%;
  }
  @media (max-width: 479px) {
    .left .badge-text{
      font-size: 0.6rem;
      top: calc(50% - 1.1rem);
    }
    .left .badge-text.multi-row{
      left: 0;
      line-height: 0.5rem;
    }
  }
</style>
